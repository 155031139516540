import * as React from "react";
import {
  Typography,
  Button,
  Divider,
  Paper,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import {
  CreateAgreementToSpots,
  CreateAgreementToChassisRental,
} from "../../../services/PaymentStripeServices";
import Cookies from "universal-cookie";
import CloudUploadIcon from "@mui/icons-material/UploadFile";
import DetailSummaryInnerTable from "./DetailSummaryInnerTable";
import DetailSummaryNotes from "./DetailSummaryNotes";
import DetailSummaryFooter from "./DetailSummaryFooter";
import TermsOfUseDialog from "./TermsOfUseDialog";
import RentalAgreementDialog from "./RentalAgreementDialog";
import PaymentPage from "../../StripeEmbedd/PaymentPage/PaymentPage";
import { styled } from "@mui/material/styles";
import "./DetailSummary.css";

import { useSnackBar } from "../../../context/SnackBarContext";
import {
  getItemFromLocalStorage,
  editItemInLocalStorage,
  updateItemInCart,
} from "../../../services/localStorageService";

const DetailSummaryCard = ({ activeProduct, onActiveProductChange }) => {
  const cookies = new Cookies();
  const [paymentState, setPaymentState] = React.useState(false);
  const [processingAgreement, setProcessingAgreement] = React.useState(false);
  const [agreementResponse, setAgreementResponse] = React.useState(null);
  const [localActiveProduct, setLocalActiveProduct] = React.useState({});
  const { handleRenderSnackBar } = useSnackBar();
  const [contractInsurance, setContractInsurance] = React.useState({
    fileName: "",
    file: "",
    type: "",
  });
  const [contractInsuranceUploaded, setContractInsuranceUploaded] =
    React.useState(false);
  const handleChangePrivacy = (privacyPolicyAgreed) => {
    activeProduct.product.privacyPolicyAgreed = privacyPolicyAgreed;

    onActiveProductChange(activeProduct.product, activeProduct.index);
    updateItemInCart("yardProducts", activeProduct.index, {
      ...activeProduct.product,
    });
    window.dispatchEvent(new Event("localStorageHasChanged"));
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  //FUNCTIONS ----------------------------------------------------------------------------------------

  function handleSetAgreementObjectStructure() {
    const sessionCosmos = cookies.get("sessionCosmos");
    localActiveProduct.UserRequest = sessionCosmos.email[0];
    localActiveProduct.CarrierClient = sessionCosmos.scac_code;
    delete localActiveProduct.EndDate;
    return {
      payload: localActiveProduct,
    };
  }

  function handleSetAgreementObjectStructureToChassisRental(index) {
    const itemFromCart = getItemFromLocalStorage("yardProducts");

    const itemProduct = itemFromCart[index];
    const sessionCosmos = cookies.get("sessionCosmos");

    localActiveProduct.CarrierClient = sessionCosmos.scac_code;
    if (localActiveProduct.File) {
      delete localActiveProduct.File;
    }

    localActiveProduct.ProductsChosen.ChassisSelected =
      localActiveProduct.ProductsChosen.YardSelected;
    delete localActiveProduct.ProductsChosen.YardSelected;

    delete localActiveProduct.EndDate;
    delete localActiveProduct.itemType;

    return {
      payload: localActiveProduct,
      UserRequest: sessionCosmos.email[0],
      File: itemProduct.File,
    };
  }

  function handleSetPaymentObjectInLocalStorage(response, itemProduct) {
    const existingPaymentObjects = getItemFromLocalStorage("paymentObject");
    const newPaymentObject = {
      response,
      itemProduct,
    };
    function arePaymentObjectsEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    let updatedPaymentObjects;

    if (existingPaymentObjects) {
      const objectExists = existingPaymentObjects.some((obj) => {
        if (obj.idEnvelope === newPaymentObject.idEnvelope) return true;
      });

      if (objectExists) {
        return;
      }
      updatedPaymentObjects = [...existingPaymentObjects, newPaymentObject];
    } else {
      updatedPaymentObjects = [newPaymentObject];
    }
    editItemInLocalStorage("paymentObject", updatedPaymentObjects);

    window.dispatchEvent(new Event("paymentObjectCreated"));
  }

  function handleCreateAgreement() {
    let data;
    setProcessingAgreement(true);
    if (activeProduct.product.itemType === "Chassis") {
      data = handleSetAgreementObjectStructureToChassisRental(
        activeProduct.index
      );
      CreateAgreementToChassisRental(data).then((response) => {
        if (!response.firstPaymentIds && !response.NewIdAgreement) {
          setProcessingAgreement(false);
          handleRenderSnackBar(response.Message, "error");
          return;
        }
        setPaymentState(true);
        window.location.href =
          window.location.href + `?AgreementId=${response.NewIdAgreement}`;
      });
    } else {
      data = handleSetAgreementObjectStructure();
      CreateAgreementToSpots(data).then((response) => {
        if (!response.firstPaymentIds) {
          setProcessingAgreement(false);
          handleRenderSnackBar(response.data, "error");
          return;
        }
        setAgreementResponse(response);
        setPaymentState(true);
        setProcessingAgreement(false);
      });
    }
  }

  function handleOrderChassis() {}

  function checkIfPaymentExistInLocalStorage() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (paymentObject !== null || undefined) {
      return paymentObject;
    }
  }

  //USE EFFECTS ----------------------------------------------------------------------------------------

  React.useEffect(() => {
    const paymentExist = checkIfPaymentExistInLocalStorage();
    if (paymentExist) {
      setPaymentState(true);
      setAgreementResponse(paymentExist[0].response);
    }
  }, []);

  React.useEffect(() => {
    if (!activeProduct.product.UserRequest) {
      if (
        activeProduct.product.itemType === "Chassis" &&
        activeProduct.product.File
      ) {
        setContractInsuranceUploaded(true);
      }
      setLocalActiveProduct(JSON.parse(JSON.stringify(activeProduct.product)));
    }
  }, [activeProduct]);

  React.useEffect(() => {
    if (activeProduct.product.EndDate && paymentState) {
      handleSetPaymentObjectInLocalStorage(
        agreementResponse,
        activeProduct.product
      );
    }
  }, [paymentState]);

  const activeProductAgreementSigned = activeProduct.product.idEnvelope
    ? true
    : false;

  const activeProductPrivacyPolicyAgreed =
    activeProduct.product.privacyPolicyAgreed;

  const error =
    !activeProductPrivacyPolicyAgreed || !activeProductAgreementSigned;

  const handleConvertFileToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleChangeItemInStorage = (fileName, fileConverted) => {
    const cart = getItemFromLocalStorage("yardProducts");
    cart[activeProduct.index].File = {
      FileName: fileName,
      FileContent: fileConverted,
    };
    editItemInLocalStorage("yardProducts", cart);
  };

  const handleUploadPDF = async (e) => {
    const file = e;
    const reader = new FileReader();
    const fileConverted = await handleConvertFileToBase64(file);
    reader.onloadend = () => {
      setContractInsurance({
        fileName: file.name,
        file: fileConverted,
        type: file.type,
      });
      handleChangeItemInStorage(file.name, fileConverted);
      setContractInsuranceUploaded(true);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          minHeight: "60vh",
          alignContent: "center",
          borderRadius: 5,
          bgcolor: "white.main",
          zIndex: "bottom",
          px: 3,
          py: 2,
        }}
      >
        {processingAgreement ? (
          <Box className="LoginReact-circular-progress-box">
            <CircularProgress
              thickness={7}
              className="LoginReact-circular-progress"
            />
          </Box>
        ) : (
          <>
            {paymentState ? (
              <PaymentPage AgreementResponse={agreementResponse} />
            ) : (
              <>
                <Typography
                  color="primary.dark"
                  style={{
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  Summary for{" "}
                  {activeProduct.product.ProductsChosen.YardSelected.CodeName}
                </Typography>
                <Divider
                  variant="fullWidth"
                  sx={{ borderColor: "primary.dark", border: 1 }}
                />
                <DetailSummaryInnerTable
                  activeProduct={activeProduct.product}
                />
                <DetailSummaryNotes />
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <>
                    <FormControl
                      required
                      error={error}
                      component="fieldset"
                      sx={{ m: 0, width: "100%" }}
                      variant="filled"
                    >
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                                color: "#6C6B6B",
                              }}
                              checked={
                                activeProduct.product.privacyPolicyAgreed
                                  ? true
                                  : false
                              }
                              name="termsOfUsePolicy"
                              onClick={() => {
                                window.dispatchEvent(
                                  new Event("openPrivacyPolicy")
                                );
                              }}
                            />
                          }
                          label={
                            <TermsOfUseDialog
                              activeProduct={activeProduct}
                              onPrivacyPolicyChange={handleChangePrivacy}
                            />
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                                color: "#6C6B6B",
                              }}
                              checked={
                                activeProduct.product.idEnvelope ? true : false
                              }
                              disabled={
                                activeProduct.product.idEnvelope ? true : false
                              }
                              name="rentalAgreementPolicy"
                            />
                          }
                          label={
                            <RentalAgreementDialog
                              activeProduct={activeProduct}
                              onActiveProductChange={onActiveProductChange}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: error ? "space-between" : "flex-end",
                    alignItems: "center",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    my: 2,
                  }}
                >
                  {error && (
                    <Typography
                      color="secondary"
                      style={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      Please approve the terms and contract to proceed
                    </Typography>
                  )}
                  {activeProduct.product.itemType === "Chassis" && (
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      className="CartView-DetailSummaryCard-UploadButton"
                    >
                      {contractInsurance?.fileName
                        ? contractInsurance?.fileName
                        : localActiveProduct?.File?.FileName
                        ? localActiveProduct?.File?.FileName
                        : "Upload contract insurance"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => {
                          handleUploadPDF(event.target.files[0]);
                        }}
                      />
                    </Button>
                  )}
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={
                      error ||
                      (!contractInsuranceUploaded &&
                        activeProduct.product.itemType === "Chassis")
                    }
                    onClick={() => {
                      handleCreateAgreement();
                    }}
                    sx={{ width: 150, borderRadius: 2, marginLeft: "10px" }}
                  >
                    <Typography textTransform="capitalize" variant="button">
                      {activeProduct.product.itemType === "Chassis"
                        ? "Order Now"
                        : "Buy now"}
                    </Typography>
                  </Button>
                </Box>

                <Divider
                  variant="fullWidth"
                  sx={{ borderColor: "primary.dark", border: 1 }}
                />
                <DetailSummaryFooter />
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default DetailSummaryCard;
